#ArticleList{
    width: auto;
    height: auto;
    left: 0;
    margin: 0;
    background: white;
}

#previewImageContainer {
    width: auto;
    padding: 10px;
    height: auto;
    margin: 5%;
    display: flex;
}
#previewImage {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
}

#noArticlesAnnouncement{
    font-size: 2em;
    padding: 5%;
    margin: 2.5em;
}
