*{
    height:100%;
}

body{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    background: darkslategrey;
}



.LandingContainer{
    position: absolute;
    margin-left: auto;
    right: 15%;
    width: 70%;
    height: auto;
    display: block;
    text-align: center;
    vertical-align: middle;
}


.LoginForm{
    display: inline-grid;
    height: 70vh;
    grid-template-rows: 1fr 2fr;
}

.button {
    display: inline;
    width: 60%;
    padding: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: auto;
    height: 40px;
}

.newestPost{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    height: 100px;
}

.loginFormHeader{
    width: auto;
}