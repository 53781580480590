@media screen and (max-width: 700px) {
    section#sidebar {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
      top: 0;
    }
    section#sidebar a {float: left;}
    section.content {margin-left: 0;}
  }

  
  @media screen and (max-width: 400px) {
    section#sidebar a {
      text-align: center;
      float: none;
    }
  }

  

* {
    height: 100%;
    padding: 0;
}

body {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
        align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    background: white;
}

h3{
    height: auto;
}

#sidebar {
    width: auto;
    height: auto;
    padding: 10px;
    padding-right: 2%;
    right: 0;
    margin: 0;
    color: white;
    text-decoration: none;
    background: white;
    
}

#searchDiv, #favoritesDiv, #categoriesDiv, #tagsDiv {
    width: auto;
    height: auto;
    margin: 0;
    padding-right: 10%;

}

.navbar-searchDiv, .navbar-favoritesDiv, .navbar-categoriesDiv {
    width: auto;
    height: auto;
    margin: 0;
    padding-right: 10%;
font-size: inherit;
color: white;
display: inherit;
}

.navbar-favoritesDiv a, .navbar-categoriesDiv a {
color: white;
}

input{
    height: auto;
    width: auto;
}

#sidebarHeadline{
    color: black;
    font-size: 1.5em;
    text-decoration-line: underline;
}