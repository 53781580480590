@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Sulphur+Point&display=swap');

@media screen and (max-width: 700px) {
  h1#headerTitle {
    width: auto;
    font-family: 'Roboto Mono', monospace;
    font-size: 3em;
    padding: 7%;
}

section#desktop-navbar, section#sidebar, section.hidden {
    display: none;
}

#navbarFavorites{
    text-shadow: 4px 4px 8px rgb(0, 0, 0, .5);
}

section.mobile-navbar {
    padding-top: 5%;
    width: 100%;
    margin: 0;
    height: auto;
    position: sticky;
    background: rgb(172, 172, 172);
    color: white;
   display: inline-block;
   font-size: 1em;
}
section#Header, section#Header::after{
    height: auto;
}
section#contenContainer{
    display: block;
}

section#ArticleList{
    display: block;
    z-index: 5;
}

section#article{
    display: block;
    padding: 5%;
    z-index: 5;
}

}


* {
    height: 100%;
    padding: 0;
    font-family: 'Sulphur Point', sans-serif;
}

body {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    background: white;
}

header {
    margin: auto;
    padding: 3em;

}

#headerTitle {
    font-family: 'Roboto Mono', monospace;
    font-size: 4em;
    text-align: left;
    vertical-align: middle;
    margin: 0;
    color: rgb(117, 233, 233)
}

#dashboardContainer,
main {
    width: 100vw;
    height: auto;
    display: block;
}

#contenContainer {
    width: 100%;
    height: auto;
    margin: auto;
    vertical-align: middle;
    text-align: center;
    display: inline-grid;
    grid-template-columns: 3fr 1fr;
    grid-row-gap: 0;
    grid-column-gap: 0;
}


h3 {
    height: auto;
}

#Header {
    width: auto;
    height: 200px;
    margin: 0;
    top: 0;
    color: white;
    padding: 2%;
}

#navLink{
    color: white;
    padding: 2%;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, .5);
}

#Header::after {
    width: 100vw;
    content: "";
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)), url(/images/headerImage.jpeg);
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

h1 {
    height: auto;
}

navbar {
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr;
}

#desktop-navbar {
    width: 100%;
    height: 30px;
    position: sticky;
    background: none;
    color: white;
   display: inline-block;
   padding-top: 1%;
   padding-bottom: 1%;
   font-size: 2em;
   margin-bottom: 2%;
}

.mobile-navbar {
    display: none;
}
section.hidden {
    display: none;
}