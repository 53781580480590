button {
    background: rgb(66, 66, 66);
    color: white;
    padding: 2%;
    margin: 2%;
    border: none;
    height: auto;
}

#articleImage {
    width: auto;
    margin: 10px;
    height: auto;
    margin-top: 5%;
    display: inline-block;
}



#mainContentContainer {
    width: 100%;
    height: auto;
    margin-top: 0;
    padding: 2%;
    display: inline-block;
}

#article-full-container {
    width: auto;
    height: 100%;
    left: 0;
    margin: 0;
    background: white;
}

#article {
    width: auto;
    height: auto;
    margin-top: 2%;
    vertical-align: middle;
    display: inline-grid;
    grid-template-columns: 1fr 2fr;
}

#article-Full {
    width: 100%;
    height: auto;
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    background: white;
}


#articleText {
    width: 90%;
    display: block;
    height: auto;
    margin: auto;
    padding: 2%;
}

#articleText {
    width: 90%;
    display: block;
    height: auto;
    margin: auto;
    padding: 2%;
}

#articleText-Full {
    width: 70%;
    display: block;
    height: auto;
    margin: auto;
    background: white;
    padding: 2%;
}

#articleButtons {
    height: auto;
    padding: 10px;
    background: white;
}

#articleComments {
    height: auto;
}

#commentContainer {
    height: auto;
    background: rgb(180, 180, 180);
    text-align: left;
    padding: 5%;
}

#articleComment {
    height: auto;
    text-align: left;
    padding: 5%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    background-color: rgb(194, 194, 194);
    border-radius: 20px;
    box-shadow: 9px 10px 14px -4px rgba(44, 44, 44, 0.75);
}

#addComment {
    height: 100px;
}

#newComment {
    height: 150px;
    margin: 10px;
}


.commentContent {
    grid-area: 1 / 1 / 3 / 2;
    margin-left: 0;
    width: 100%;
    padding: 1px 3%;
}

.deleteComment {
    grid-area: 1 / 2 / 2 / 3;
}

.commentOwner {
    margin: 0;
}

.commenterName {
    text-align: left;
    width: 100%;
    height: auto;
}

.deleteCommentButton {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 1%;
}