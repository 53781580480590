@import url(https://fonts.googleapis.com/css?family=Roboto+Mono|Sulphur+Point&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono|Sulphur+Point&display=swap);
@media screen and (max-width: 700px) {
    h1.lpHeaderText{
    font-size: 2.5em;
}
}

body{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    background: darkslategrey;
}

#lpArticle-container {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    vertical-align: middle;
    display: block;
}

.LandingContainer{
    position: absolute;
    margin-left: auto;
    right: 15%;
    width: 70%;
    height: auto;
    display: block;
    color: white;
    text-align: center;
    vertical-align: middle;
}

.lpButton{
    font-family: 'Roboto Mono', monospace;
    color: rgb(117, 233, 233);
    background-color: black;
    width: 200px;
    height: 100%;
}

.lpHeaderText{
    font-family: 'Roboto Mono', monospace;
    color: rgb(117, 233, 233);
    font-size: 5em;
}

#lpArticleImage {
    width: 90%;
    margin: auto;
    height: auto;
    display: block;
}

#lpArticle{
    width: 100%;
    height: auto;
    vertical-align: middle;
    display: block;
}
button {
    background: rgb(66, 66, 66);
    color: white;
    padding: 2%;
    margin: 2%;
    border: none;
    height: auto;
}

#articleImage {
    width: auto;
    margin: 10px;
    height: auto;
    margin-top: 5%;
    display: inline-block;
}



#mainContentContainer {
    width: 100%;
    height: auto;
    margin-top: 0;
    padding: 2%;
    display: inline-block;
}

#article-full-container {
    width: auto;
    height: 100%;
    left: 0;
    margin: 0;
    background: white;
}

#article {
    width: auto;
    height: auto;
    margin-top: 2%;
    vertical-align: middle;
    display: inline-grid;
    grid-template-columns: 1fr 2fr;
}

#article-Full {
    width: 100%;
    height: auto;
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    background: white;
}


#articleText {
    width: 90%;
    display: block;
    height: auto;
    margin: auto;
    padding: 2%;
}

#articleText {
    width: 90%;
    display: block;
    height: auto;
    margin: auto;
    padding: 2%;
}

#articleText-Full {
    width: 70%;
    display: block;
    height: auto;
    margin: auto;
    background: white;
    padding: 2%;
}

#articleButtons {
    height: auto;
    padding: 10px;
    background: white;
}

#articleComments {
    height: auto;
}

#commentContainer {
    height: auto;
    background: rgb(180, 180, 180);
    text-align: left;
    padding: 5%;
}

#articleComment {
    height: auto;
    text-align: left;
    padding: 5%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    background-color: rgb(194, 194, 194);
    border-radius: 20px;
    box-shadow: 9px 10px 14px -4px rgba(44, 44, 44, 0.75);
}

#addComment {
    height: 100px;
}

#newComment {
    height: 150px;
    margin: 10px;
}


.commentContent {
    grid-area: 1 / 1 / 3 / 2;
    margin-left: 0;
    width: 100%;
    padding: 1px 3%;
}

.deleteComment {
    grid-area: 1 / 2 / 2 / 3;
}

.commentOwner {
    margin: 0;
}

.commenterName {
    text-align: left;
    width: 100%;
    height: auto;
}

.deleteCommentButton {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 1%;
}
*{
    height:100%;
}

body{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    background: darkslategrey;
}



.LandingContainer{
    position: absolute;
    margin-left: auto;
    right: 15%;
    width: 70%;
    height: auto;
    display: block;
    text-align: center;
    vertical-align: middle;
}


.LoginForm{
    display: inline-grid;
    height: 70vh;
    grid-template-rows: 1fr 2fr;
}

.button {
    display: inline;
    width: 60%;
    padding: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: auto;
    height: 40px;
}

.newestPost{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    height: 100px;
}

.loginFormHeader{
    width: auto;
}
@media screen and (max-width: 700px) {
    div.signupButtons {
    display: block;
    text-align: center;
    height: auto;
}
}

body{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}


.LandingContainer{
    position: absolute;
    margin-left: auto;
    right: 15%;
    width: 70%;
    height: auto;
    display: block;
    text-align: center;
    vertical-align: middle;
}


.SignUpForm{
display: inline-grid;
height: 70vh;
grid-template-rows: 1fr 2fr;
}

.RegistrationForm{
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
    grid-column-gap: 1.5em;
    text-align: left;
}

.formLine{
    display: inline-grid;
    grid-template-columns: 1fr 2fr;
    height: auto;
    margin: auto;
}

#passwordDesc{
    text-align: center;
}

.signupButtons{
    display: inline-grid;
    grid-template-columns: 0fr 0fr;
    grid-column-gap: inherit;
    margin: auto;
}

.button {
    display: inline;
    width: 60%;
    padding: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: auto;
    height: 40px;
}

.newestPost{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    height: 100px;
}
@media screen and (max-width: 700px) {
    section#sidebar {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
      top: 0;
    }
    section#sidebar a {float: left;}
    section.content {margin-left: 0;}
  }

  
  @media screen and (max-width: 400px) {
    section#sidebar a {
      text-align: center;
      float: none;
    }
  }

  

* {
    height: 100%;
    padding: 0;
}

body {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
        align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    background: white;
}

h3{
    height: auto;
}

#sidebar {
    width: auto;
    height: auto;
    padding: 10px;
    padding-right: 2%;
    right: 0;
    margin: 0;
    color: white;
    text-decoration: none;
    background: white;
    
}

#searchDiv, #favoritesDiv, #categoriesDiv, #tagsDiv {
    width: auto;
    height: auto;
    margin: 0;
    padding-right: 10%;

}

.navbar-searchDiv, .navbar-favoritesDiv, .navbar-categoriesDiv {
    width: auto;
    height: auto;
    margin: 0;
    padding-right: 10%;
font-size: inherit;
color: white;
display: inherit;
}

.navbar-favoritesDiv a, .navbar-categoriesDiv a {
color: white;
}

input{
    height: auto;
    width: auto;
}

#sidebarHeadline{
    color: black;
    font-size: 1.5em;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
#ArticleList{
    width: auto;
    height: auto;
    left: 0;
    margin: 0;
    background: white;
}

#previewImageContainer {
    width: auto;
    padding: 10px;
    height: auto;
    margin: 5%;
    display: flex;
}
#previewImage {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
}

#noArticlesAnnouncement{
    font-size: 2em;
    padding: 5%;
    margin: 2.5em;
}

@media screen and (max-width: 700px) {
  h1#headerTitle {
    width: auto;
    font-family: 'Roboto Mono', monospace;
    font-size: 3em;
    padding: 7%;
}

section#desktop-navbar, section#sidebar, section.hidden {
    display: none;
}

#navbarFavorites{
    text-shadow: 4px 4px 8px rgb(0, 0, 0, .5);
}

section.mobile-navbar {
    padding-top: 5%;
    width: 100%;
    margin: 0;
    height: auto;
    position: -webkit-sticky;
    position: sticky;
    background: rgb(172, 172, 172);
    color: white;
   display: inline-block;
   font-size: 1em;
}
section#Header, section#Header::after{
    height: auto;
}
section#contenContainer{
    display: block;
}

section#ArticleList{
    display: block;
    z-index: 5;
}

section#article{
    display: block;
    padding: 5%;
    z-index: 5;
}

}


* {
    height: 100%;
    padding: 0;
    font-family: 'Sulphur Point', sans-serif;
}

body {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    background: white;
}

header {
    margin: auto;
    padding: 3em;

}

#headerTitle {
    font-family: 'Roboto Mono', monospace;
    font-size: 4em;
    text-align: left;
    vertical-align: middle;
    margin: 0;
    color: rgb(117, 233, 233)
}

#dashboardContainer,
main {
    width: 100vw;
    height: auto;
    display: block;
}

#contenContainer {
    width: 100%;
    height: auto;
    margin: auto;
    vertical-align: middle;
    text-align: center;
    display: inline-grid;
    grid-template-columns: 3fr 1fr;
    grid-row-gap: 0;
    grid-column-gap: 0;
}


h3 {
    height: auto;
}

#Header {
    width: auto;
    height: 200px;
    margin: 0;
    top: 0;
    color: white;
    padding: 2%;
}

#navLink{
    color: white;
    padding: 2%;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, .5);
}

#Header::after {
    width: 100vw;
    content: "";
    background-image: -webkit-gradient( linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), color-stop(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 1))), url(/images/headerImage.jpeg);
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)), url(/images/headerImage.jpeg);
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

h1 {
    height: auto;
}

navbar {
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr;
}

#desktop-navbar {
    width: 100%;
    height: 30px;
    position: -webkit-sticky;
    position: sticky;
    background: none;
    color: white;
   display: inline-block;
   padding-top: 1%;
   padding-bottom: 1%;
   font-size: 2em;
   margin-bottom: 2%;
}

.mobile-navbar {
    display: none;
}
section.hidden {
    display: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(66, 66, 66);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #000;
  color: initial;
}

div.hidden{
  display: none;
}
