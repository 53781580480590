@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Sulphur+Point&display=swap');
@media screen and (max-width: 700px) {
    h1.lpHeaderText{
    font-size: 2.5em;
}
}

body{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    background: darkslategrey;
}

#lpArticle-container {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    vertical-align: middle;
    display: block;
}

.LandingContainer{
    position: absolute;
    margin-left: auto;
    right: 15%;
    width: 70%;
    height: auto;
    display: block;
    color: white;
    text-align: center;
    vertical-align: middle;
}

.lpButton{
    font-family: 'Roboto Mono', monospace;
    color: rgb(117, 233, 233);
    background-color: black;
    width: 200px;
    height: 100%;
}

.lpHeaderText{
    font-family: 'Roboto Mono', monospace;
    color: rgb(117, 233, 233);
    font-size: 5em;
}

#lpArticleImage {
    width: 90%;
    margin: auto;
    height: auto;
    display: block;
}

#lpArticle{
    width: 100%;
    height: auto;
    vertical-align: middle;
    display: block;
}