@media screen and (max-width: 700px) {
    div.signupButtons {
    display: block;
    text-align: center;
    height: auto;
}
}

body{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}


.LandingContainer{
    position: absolute;
    margin-left: auto;
    right: 15%;
    width: 70%;
    height: auto;
    display: block;
    text-align: center;
    vertical-align: middle;
}


.SignUpForm{
display: inline-grid;
height: 70vh;
grid-template-rows: 1fr 2fr;
}

.RegistrationForm{
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
    grid-column-gap: 1.5em;
    text-align: left;
}

.formLine{
    display: inline-grid;
    grid-template-columns: 1fr 2fr;
    height: auto;
    margin: auto;
}

#passwordDesc{
    text-align: center;
}

.signupButtons{
    display: inline-grid;
    grid-template-columns: 0fr 0fr;
    grid-column-gap: inherit;
    margin: auto;
}

.button {
    display: inline;
    width: 60%;
    padding: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: auto;
    height: 40px;
}

.newestPost{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    height: 100px;
}